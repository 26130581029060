import 'babel-polyfill'
import 'isomorphic-fetch'
import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import Img from 'gatsby-image'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import DividerMid from '../../components/elements/dividerMid'
import PreviewVideo from '../../components/elements/previewVideo'
import FeatureIcon from '../../components/marketing/featureIcon'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import PlatformCallout from '../../components/marketing/platformCallout'
import FeatureQuad from '../../components/marketing/featureQuad'

import NavSubAbout from '../../components/nav/navSubAbout'

// import NavSubAbout from "../../components/nav/navSubAbout"
// import BenefitsBoomerang from "../../components/marketing/benefitsBoomerang"

import { FaCommentDots, FaLayerGroup, FaArrowRight } from 'react-icons/fa'
import { MdPersonPin, MdScreenShare } from 'react-icons/md'

const useCaseData = [
  {
    title: 'Design, powered by social connections',
    description:
      'Stay inspired with the freshest designs by following designers & remixing content to engage your audience',
    image: 'featureGettingStarted',
    iconShow: true,
    iconContext: 'recentactors',
    iconColor: 'pink',
    iconcolor: 'white',
    iconfill: 'white',
    lineClass: 'dkgreen-1',
    anchor: 'equip',
  },
  // {
  //   title: 'Voice-driven content',
  //   description:
  //     'Personalize branded templates via AI-powered voice commands (or drag-and-drop too)',
  //   image: 'featurePublisherVoice',
  //   iconShow: true,
  //   iconContext: 'microphone',
  //   iconColor: 'blue',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'sky-1',
  //   anchor: 'images',
  // },
  {
    title: 'Hyper-relevant content',
    description:
      'Find and follow templates from designers in your industry and customize them to drive impact',
    image: 'featureSocialSearchRealestate',
    iconShow: true,
    iconContext: 'bullseye',
    iconColor: 'sky',
    iconcolor: 'white',
    iconfill: 'white',
    lineClass: 'yellow-1',
    anchor: 'discover',
  },
  {
    title: 'Create anywhere',
    description:
      'Quickly create visually rich social assets on mobile, tablet or desktop — no app download or install required',
    image: 'featureRestaurantSoloShort',
    iconShow: true,
    iconContext: 'iphone',
    iconColor: 'yellow',
    iconcolor: 'white',
    iconfill: 'white',
    lineClass: 'dkorange-1',
    anchor: 'create',
  },
  {
    title: 'Rapid sharing',
    description:
      'Share customized content to social networks using rapid sharing links, or deliver to users via SMS for posting',
    image: 'featurePublisherSharingTravel',
    iconShow: true,
    iconContext: 'lightning',
    iconColor: 'dkorange',
    iconcolor: 'white',
    iconfill: 'white',
    // lineClass: 'pink-1',
    anchor: 'share',
  },

  // {
  //   title: 'Short-form Videos',
  //   description:
  //     'Produce customized videos about your products for Instagram Stories, TikTok and more - all based on brand templates. COMING SOON',
  //   image: 'featurePublisherVideoTrimmer',
  //   iconShow: true,
  //   iconContext: 'videocamera',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'sky-1',
  //   anchor: 'video',
  // },
]

const featureData = {
  title: 'Benefits',
  titlecolor: 'white-1',
  mode: 'trio',
  bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Brand templates',
          colbgcolor: 'sky-1',
          iconname: 'bullseye',
          description:
            'Ensure on-brand content across teams and affiliates with brand templates',
        },
        {
          title: 'Rapid creation',
          colbgcolor: 'bluegreen-1',
          iconname: 'lightning',
          description:
            'Easily create visual, templated social assets and short videos using drag-and-drop',
        },
        {
          title: 'Drive sales',
          colbgcolor: 'dkblue-1',
          iconname: 'moneycheck',
          description:
            'Drive engagement with content with hyper-relevant industry templates, versus generic ones',
        },
      ],
    },
  ],
}

const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Platform - Visual Selling"
      meta={[
        {
          name: 'description',
          content:
            'Create social images and short videos for sales and marketing teams using Storylava',
        },
      ]}
    />

    <NavSubAbout />
    {/* <div class="gradient-callout-cousteau">
      <div class="container container-page container-about-sub">
        <div class="row">
          <div class="col-lg-8 d-flex flex-column justify-content-center text-center text-lg-left">
            <h1 class="display-2 font-weight-medium white-1">
              Drive sales with hyper-relevant content
            </h1>
            <p class="display-4 white-4">
              Customize images &amp; videos from brand templates to produce
              leads and drive sales
            </p>
          </div>
        </div>
      </div>
    </div> */}

    <div class="callout-home d-flex align-items-center justify-content-center">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <h1 class="text-center font-weight-normal">
              Remix customizable templates from designers you follow
            </h1>
          </div>
        </div>
      </div>
      <div class="overlay-warm"></div>
      {/* <Img
        css={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '300px',
          zIndex: 10,
        }}
        style={{ position: `absolute` }}
        fluid={props.data.jumbotron.childImageSharp.fluid}
      /> */}
      <StaticImage
        src="../../images/feature/storylava-ui-tour-4up-standing.png"
        alt="Storylava"
        loading="lazy"
        placeholder="blurred"
        layout="fullWidth"
        // width={800}
        // height={400}
        // style={{ position: `absolute` }}
        style={{
          position: `absolute`,
          // top: 0,
          // left: 0,
          // right: 0,
          // bottom: 0,
          inset: 'auto',
          width: '100%',
          height: 400,
          zIndex: 0,
        }}
      />
    </div>

    <div class="border-bottom-1-sky-1 gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="font-weight-light text-center white-1">
                Untether your teams with a platform to discover designs and
                create compelling brand content
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-lg-8 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium blue-1">
            Equip teams with on-brand, customizable content
          </h1>
          <p class="display-4">
            Customize images &amp; videos from brand templates to produce leads
            and drive sales
          </p>
        </div>
      </div>
    </div> */}

    {/* <DividerMid
      class="transparent-1"
      bottomclass="blue-1"
      flip={true}
      lineclass="sky-1"
      lineoffset={4}
      lineflip={true}
      lineclass2="yellow-1"
      lineoffset2={6}
      lineflip2={false}
      // lineclass3="yellow-1"
      // lineoffset3={10}
      // lineflip3={false}
    /> */}

    {/* <div class="gradient-callout-cousteau">
      <div class="container container-page my-0 py-4">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 d-flex flex-column justify-content-center text-center text-lg-center">
            <h2 class="font-weight-medium white-1">
              Drive sales with hyper-relevant content
            </h2>
            <p class="display-4 white-1">
              Untether your sales affiliates with a platform to create
              templated, customizable brand content
            </p>
          </div>
        </div>
      </div>
    </div> */}
    {/* <DividerMid class="green-1" line={true} /> */}

    {/* <div class="container container-page"><PlatformCallout /></div> */}

    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
            <h2 class="font-weight-light black-1 display-2">
              Storylava capabilities
            </h2>
          </div>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* <FeatureQuad featureData={featureData} /> */}
    {/* 
    <div class="gradient-callout-cousteau">
      <div class="container container-page my-4 py-4">
        <div class="row d-flex flex-row align-items-center justify-content-center round-corners-top">
          <div class="col-lg-6 d-flex flex-column justify-content-center text-center text-lg-center">
            <h2 class="font-weight-medium white-1 py-3 display-3">
              Why Storylava?
            </h2>
            <p class="display-4 white-1">
              See how Storylava is different from templated design tools
            </p>
            <Link
              to="/about/why-storylava/"
              class="btn btn-cta-outline mt-2 mb-4"
            >
              Learn more <FaArrowRight class="btn-arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div> */}

    {/* <BenefitsBoomerang
      bgcolor="seafoam-1"
      gradientOverlay={true}
      containerTitle="Benefits for brands"
      data={[
        {
          title: "Reach Gen Z + Millennials",
          body:
            "Overcome short attention spans with short, fast videos and the latest video effects",
        },
        {
          title: "Sharpen your social strategy",
          body:
            "Activate audiences on TikTok, Snapchat, and Instagram beyond just paid ads",
        },
        {
          title: "Rebuild & rebound",
          body:
            "Energize your community to get through crisis periods, and rebound using cause-based marketing",
        },
      ]}
    /> */}
  </Layout>
)

export default About

export const pageQuery = graphql`
  query {
    jumbotron: file(
      relativePath: { eq: "feature/storylava-ui-tour-4up-standing.png" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1800, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
